'use client';
import Link from 'next/link';
import styles from './index.module.css';
import Button from '@mui/material/Button';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
type Props = {
  href: string;
  children: React.ReactNode;
  isExternal?: boolean;
  size?: string;
};

export default function ButtonLink({ href, children, isExternal = false, size = 'medium' }: Props) {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#2CC09C',
      },
    },
  });
  const ButtonSize = (size == "medium" || size == "small" || size == "large") ? size : "medium";

  if (isExternal) {
    return (
      <a href={href} className={styles.button} target="_blank" rel="noopener">
        {children}
      </a>
    );
  }

  return (
    <Link href={href}>
      <ThemeProvider theme={theme}>
        <Button
          variant="contained"
          color="primary"
          size={ButtonSize}
          endIcon={<ArrowForwardOutlinedIcon />}
          style={{ color: 'white' }}
        >
          {children}
        </Button>
      </ThemeProvider>
    </Link>
  );
}
